<template>
    <div id="pwChangePage">
      <div class="container">
        <div class="input-list">
          <div class="input-item">
            <input class="input" v-model="subData.password" :type="passwordActive1?'text':'password'" placeholder="パスワードを入力">
            <i class="icon icon-eyes" :class="{active: passwordActive1}" @click="passwordActive1 = !passwordActive1"></i>
          </div>
          <div class="input-item">
            <input class="input" v-model="subData.newPassword" :type="passwordActive2?'text':'password'" placeholder="パスワードを再入力">
            <i class="icon icon-eyes" :class="{active: passwordActive2}" @click="passwordActive2 = !passwordActive2"></i>
          </div>
        </div>
        <div @click="onSubmit" class="submit-btn">
          <span>{{btnName}}</span>
        </div>
      </div>
    </div>
</template>

<script>
import md5 from 'md5';
export default {
  name: 'PwChange',
  components: {},
  data () {
    return {
      subData: {
        email: '',
        password: '',
        newPassword: ''
      },
      passwordActive1: false,
      passwordActive2: false,
      type: '',
      btnName: ''
    };
  },
  mounted () {
    const { params: { type }, meta } = this.$route;
    this.type = type;
    let title = 'パスワードの再設定';
    let btnName = '決定';
    if (type === 'modify') {
      title = 'パスワードを変更';
      btnName = '変更';
    }
    meta.title = title;
    this.btnName = btnName;
  },
  watch: {
    'subData.password' (newValue) {
      const reg = /[\u4E00-\u9FA5]|\s*/g;
      this.subData.password = newValue.replace(reg, '');
    },
    'subData.newPassword' (newValue) {
      const reg = /[\u4E00-\u9FA5]|\s*/g;
      this.subData.newPassword = newValue.replace(reg, '');
    }
  },
  methods: {
    onSubmit () {
      const { subData: { password, newPassword }, $utils, $router, $route: { params: { type }, query: { email } } } = this;
      // 验证密码
      /* 验证密码是否为空 */
      if ($utils.verifyEmpty(password, 'パスワードを入力してください')) {

        /* 验证密码的长度是否大于6小于12 */
        if ($utils.verifyLengeRange(password, 'パスワードの長さは6〜12桁です', 6, 12)) {
          if (password !== newPassword) {
            return $utils.toast('二回のパスワードが一致しません');
          }
          // 调用修改密码的接口
          switch (type) {
            case 'forgot':
              this.$http.post('security/forgetPass', {
                affirmPassword: md5(password),
                password: md5(newPassword),
                email: md5('email-code:' + email)
              }, {
                // 阻止频繁触发
                showLoading: true,
                loadingText: 'ログイン中...'
              }).then(() => {
                // 调用成功之后 提升成功信息并且跳转到成功界面onClose 方法用于当提示弹层结束后执行的事件
                $utils.successToast({
                  message: '修正成功！',
                  onClose: () => {
                    $router.replace({
                      name: 'Success',
                      params: {
                        type: 1
                      }
                    });
                  }
                });
              });
              break;
            case 'modify':
              this.$http.post('set/modifyPassword', {
                affirmPassword: md5(password),
                newPassword: md5(newPassword)
              }, {
                // 阻止频繁触发
                showLoading: true,
                loadingText: 'ログイン中...'
              }).then((res) => {
                $utils.successToast({
                  message: '修正成功！',
                  onClose: () => {
                    $router.replace({
                      name: 'Success',
                      params: {
                        type: 1
                      }
                    });
                  }
                });
              });
              break;
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
  #pwChangePage {
    padding: 62px 28px;

    .container {
      .input-list {
        .input-item {
          .icon-eyes {
            background-image: url("~@/assets/images/icon_eyesClose_01.png");

            &.active {
              background-image: url("~@/assets/images/icon_eyesOpen_01.png");
            }
          }
        }
      }

      .submit-btn {
        margin-top: 112px;
      }
    }
  }
</style>